<template lang="html">
  <v-model-table model-name="Documents" model-title="Scanned Documents"
    :model-api="['models','Document','Documents']"
    :model-api-search="computedFilter" 
    :api-with="['encounter','template']"
    api-order-by="id,DESC"
    :headers="headers"
    :insertable="false" searchable serverPagination
  >
    <template v-slot:toolBarItem="props">
      <v-date-field class="mr-1" v-model="currentDocumentDate" label="วันที่สแกน" hide-details single-line></v-date-field>
      <v-patient-select class="mr-1" v-model="currentDocumentHN" label="HN" hide-details single-line></v-patient-select>
      <v-spacer></v-spacer>
    </template>
    <template v-slot:item.created_at="props">
      <v-icon>{{ (props.item.status=='approved') ? 'mdi-checkbox-marked-circle' : 'mdi-timer-sand' }}</v-icon>
      <v-label-datetime :date-time="props.item.created_at" short-date></v-label-datetime>
    </template>
    <template v-slot:item.category="props">
      <v-edit-dialog
        :return-value.sync="props.item.category"
        @save="props.actions.saveData(props.item)"
        large
      > 
        <v-label-master group-key="documentCategory" :item-code="props.item.category"></v-label-master>
        <template v-slot:input>
          <v-master-field v-model="props.item.category" label="หมวดหมู่เอกสาร"
            :groupKey="$store.getters.getGroupKey('documentCategory')"
            show-code
          ></v-master-field>
        </template>
      </v-edit-dialog>
    </template>
    <template v-slot:item.templateCode="props">
      <v-edit-dialog
        :return-value.sync="props.item.templateCode"
        @save="props.actions.saveData(props.item)"
        large
      > 
        {{ props.item.template.templateName }}
        <template v-slot:input>
          <v-master-field v-model="props.item.templateCode"
            :custom-api="['models','Document','DocumentsTemplates','query']"
            custom-api-text="templateName"
            custom-api-value="templateCode" 
            label="ชนิดเอกสาร" show-code customApiEagerLoad
          ></v-master-field>
        </template>
      </v-edit-dialog>
    </template>
    <template v-slot:item.action="props">
      <v-icon class="mx-1" @click.stop="viewDocument(props.item)" v-if="props.item.isScanned">mdi-file-find</v-icon>
      <v-icon class="mx-1" @click.stop="printDocument(props.item.id)" v-if="!props.item.template || props.item.isScanned || props.item.template.isPrintable">print</v-icon>
      <v-icon class="mx-1" color="red darken-3" @click.stop="props.actions.deleteData(props.item)">$vuetify.icons.delete</v-icon>
    </template>
  </v-model-table>
</template>

<script>
import api from 'tantee-common/lib/api'
import {isUndefined} from 'lodash'
import printDocument from '@/components/mixins/printDocument'

export default {
  mixins: [printDocument],
  data: ()=>({
    currentDocumentDate: undefined,
    currentDocumentHN: undefined,
    headers: [
      {
        text: 'วันที่',
        value: 'created_at',
        class: 'body-2'
      },
      {
        text: 'HN',
        value: 'hn',
        class: 'body-2'
      },
      {
        text: 'EncounterID',
        value: 'encounterId',
        class: 'body-2'
      },
      {
        text: 'Folder',
        value: 'folder',
        class: 'body-2'
      },
      {
        text: 'คลินิก',
        value: 'encounter.clinic.clinicName',
        class: 'body-2'
      },
      {
        text: 'หมวดหมู่เอกสาร',
        value: 'category',
        class: 'body-2'
      },
      {
        text: 'ชนิดเอกสาร',
        value: 'templateCode',
        class: 'body-2'
      },
      {
        text: 'สถานะ',
        value: 'status',
        class: 'body-2'
      },
      {
        text: 'ผู้สร้างเอกสาร',
        value: 'created_by',
        class: 'body-2'
      },
      {
        text: 'Action',
        value: 'action',
        align: 'center',
        width: '150px',
        sortable: false,
        class: 'body-2'
      }
    ],
  }),
  computed: {
    computedFilter() {
      return {
        isScanned: true,
        status: 'approved',
        hn: this.currentDocumentHN,
        'whereDate#created_at': this.currentDocumentDate, 
      }
    }
  },
  methods: {
    viewDocument(document) {
      this.$documentViewer(document)
    }
  },
}
</script>

<style lang="css" scoped>
</style>
